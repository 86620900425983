import { safeJSONArr } from '@/utils';

export function serializeQueryState(
  data: Record<string, unknown> | Array<unknown>
) {
  return btoa(JSON.stringify(data));
}

export function parseQueryState<T>(query: string | string[] = '') {
  const raw = atob([query].flat().join(''));
  return safeJSONArr<T>(raw);
}
