import { useRef, useState, useMemo } from 'react';
import type { BaseSyntheticEvent, MouseEvent, KeyboardEvent } from 'react';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { ResizeObserver } from '@juggle/resize-observer';
import useMeasure from 'react-use-measure';
import clsx from 'clsx';
// import { useSearchBox } from 'react-instantsearch-hooks';
import { createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import type { AutocompleteState } from '@algolia/autocomplete-core';
// import debounce from 'just-debounce-it';

import { Search as SearchIcon, ArrowLeft } from '@fu/icons';

import {
  configuredAlgoliaSearchClient as searchClient,
  CURRICULUM_INDEX,
  // STUDY_PROGRAM_INDEX,
  // ARTICLE_INDEX,
  QUERY_SUGGESTIONS_INDEX,
} from '@/lib/algolia';

import { mergeRefs } from '@/utils';

import type { AutocompleteItem } from '@/components/algolia';
import { ResultsModal } from '@/components/algolia/autocomplete/results-modal';

// type SetInstantSearchUiStateOptions = {
//   query: string;
//   category?: string;
// };

export type AutocompleteProps = {
  position?: 'right' | 'center';
};

export function Autocomplete({ position = 'right' }: AutocompleteProps) {
  const router = useRouter();
  const t = useTranslations('Search');

  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const [inputBoundsRef, inputBounds] = useMeasure({
    polyfill: ResizeObserver,
  });

  // const { refine: setQuery } = useSearchBox();

  const [autocompleteState, setAutocompleteState] = useState<
    AutocompleteState<AutocompleteItem>
  >({} as AutocompleteState<AutocompleteItem>);

  // const debouncedSetQuery = debounce(setQuery, 500);

  const plugins = useMemo(() => {
    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: 'instantsearch',
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'recentSearches',
          // onSelect({ item }) {
          //   debouncedSetQuery(item.label);
          //   // setInstantSearchUiState({
          //   //   query: item.label,
          //   //   category: item.category,
          //   // });
          // },
          // templates: {
          //   ...source.templates,
          //   header({ state }) {
          //     if (state.query) {
          //       return '';
          //     }

          //     return (
          //       <>
          //         <span className="aa-SourceHeaderTitle">Your searches</span>
          //         <div className="aa-SourceHeaderLine" />
          //       </>
          //     );
          //   },
          // },
        };
      },
    });

    const querySuggestions = createQuerySuggestionsPlugin({
      searchClient,
      indexName: QUERY_SUGGESTIONS_INDEX,
      getSearchParams() {
        if (!recentSearches.data) {
          return {};
        }

        return recentSearches.data.getAlgoliaSearchParams({
          hitsPerPage: 3,
          highlightPreTag: '<mark>',
          highlightPostTag: '</mark>',
        });
      },
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestions',
          // onSelect({ item }) {
          //   debouncedSetQuery(item.query);
          //   // setInstantSearchUiState({
          //   //   query: item.query,
          //   //   category: item.__autocomplete_qsCategory || '',
          //   // });
          // },
          // getItems(params) {
          //   if (!params.state.query) {
          //     return [];
          //   }

          //   return source.getItems(params);
          // },
          // templates: {
          //   ...source.templates,
          //   header({ state }) {
          //     if (state.query) {
          //       return '';
          //     }

          //     return (
          //       <>
          //         <span className="aa-SourceHeaderTitle">Popular searches</span>
          //         <div className="aa-SourceHeaderLine" />
          //       </>
          //     );
          //   },
          // },
        };
      },
    });

    return [recentSearches, querySuggestions];
  }, []);

  const autocomplete = useMemo(
    () =>
      createAutocomplete<
        AutocompleteItem,
        BaseSyntheticEvent,
        MouseEvent,
        KeyboardEvent
      >({
        placeholder: t('placeholder'),
        openOnFocus: true,
        // initialState: { query },
        plugins,
        // onReset() {
        //   setInstantSearchUiState({ query: '' });
        // },
        onSubmit({ state }) {
          // console.log(state);

          // debouncedSetQuery(state.query);

          if (state.query) {
            router
              .push(`/sok?q=${encodeURIComponent(state.query)}`)
              .catch((err) => console.log(err));
          }

          // setInstantSearchUiState({ query: state.query });
        },
        onStateChange({ prevState, state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state);

          // if (prevState.query !== state.query) {
          //   debouncedSetQuery(state.query);
          //   // debouncedSetInstantSearchUiState({
          //   //   query: state.query,
          //   // });
          // }
        },
        getSources() {
          return [
            {
              sourceId: 'curriculum',
              // getItemInputValue({ item }) {
              //   return item.query;
              // },
              getItems({ query, setContext }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: CURRICULUM_INDEX,
                      query,
                      params: {
                        hitsPerPage: 5,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                      },
                    },
                    // {
                    //   indexName: STUDY_PROGRAM_INDEX,
                    //   query,
                    //   params: {
                    //     hitsPerPage: 5,
                    //     highlightPreTag: '<mark>',
                    //     highlightPostTag: '</mark>',
                    //   },
                    // },
                  ],
                  transformResponse({ results, hits }) {
                    setContext({
                      curricula: results.reduce(
                        (totalHits, result) =>
                          totalHits +
                          ((result as { nbHits: number }).nbHits ?? 0),
                        0
                      ),
                    });

                    return hits;
                  },
                });
              },
              // getItemUrl({ item }) {
              //   if (
              //     item._type === 'mysoft_curriculum' ||
              //     item._type === 'studd_study_program'
              //   ) {
              //     return item.pathname;
              //   }

              //   return '';
              // },
            },
            // {
            //   sourceId: 'studyProgram',
            //   // getItemInputValue({ item }) {
            //   //   return item.query;
            //   // },
            //   getItems({ query, setContext }) {
            //     return getAlgoliaResults({
            //       searchClient,
            //       queries: [
            //         {
            //           indexName: STUDY_PROGRAM_INDEX,
            //           query,
            //           params: {
            //             hitsPerPage: 5,
            //             highlightPreTag: '<mark>',
            //             highlightPostTag: '</mark>',
            //           },
            //         },
            //       ],
            //       transformResponse({ results: [result], hits }) {
            //         setContext({
            //           studyPrograms: (result as { nbHits: number }).nbHits,
            //         });

            //         return hits;
            //       },
            //     });
            //   },
            //   getItemUrl({ item }) {
            //     return item.pathname;
            //   },
            // },
            // {
            //   sourceId: 'article',
            //   // getItemInputValue({ item }) {
            //   //   return item.query;
            //   // },
            //   getItems({ query, setContext }) {
            //     return getAlgoliaResults({
            //       searchClient,
            //       queries: [
            //         {
            //           indexName: ARTICLE_INDEX,
            //           query,
            //           params: {
            //             hitsPerPage: 5,
            //             highlightPreTag: '<mark>',
            //             highlightPostTag: '</mark>',
            //           },
            //         },
            //       ],
            //       transformResponse({ results: [result], hits }) {
            //         setContext({
            //           articles: (result as { nbHits: number }).nbHits,
            //         });

            //         return hits;
            //       },
            //     });
            //   },
            //   // getItemUrl({ item }) {
            //   //   return `/artikler/${item.slug}`;
            //   // },
            // },
          ];
        },
      }),
    []
  );

  return (
    <div
      ref={panelRef}
      className={clsx(
        'relative',
        { 'z-30': autocompleteState.isOpen }
        // autocompleteState.status === 'stalled' && 'aa-Panel--stalled',
      )}
      {...autocomplete.getRootProps({})}
    >
      <form
        ref={formRef}
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
      >
        {/* <div className="aa-InputWrapperPrefix">
          <label
            className="aa-Label"
            {...autocomplete.getLabelProps({})}
            // id="autocomplete-label"
            // htmlFor="autocomplete-input"
          >
            Search
          </label>
        </div> */}
        <div className="relative flex items-center">
          {autocompleteState.isOpen && (
            <ArrowLeft
              className="mr-3 block h-6 w-6 sm:hidden"
              onClick={() => autocomplete.setIsOpen(false)}
            />
          )}
          <input
            ref={mergeRefs(inputRef, inputBoundsRef)}
            name="autocomplete"
            className={clsx(
              'h-12 w-full sm:min-w-[18rem]',
              'rounded-full border-1 border-neutral-30',
              'outline-none',
              'hover:border-neutral-60',
              'focus:state-focus-dark focus:border-neutral-30',
              'active:state-active',
              'py-2 pr-4 pl-6',
              'bg-neutral-30',
              // prevent iOS zooming on focus
              'text-base'
            )}
            {...autocomplete.getInputProps({
              inputElement: inputRef.current,
            })}
          />
          {!autocompleteState.query && (
            <span className="absolute right-4 top-2/4 -translate-y-2/4">
              <SearchIcon />
            </span>
          )}
        </div>
        {/* <div className="aa-InputWrapperSuffix">
          <button className="aa-ClearButton">x</button>
        </div> */}
      </form>

      <ResultsModal
        autocomplete={autocomplete}
        autocompleteState={autocompleteState}
        inputBounds={inputBounds}
        position={position}
      />
    </div>
  );
}

export default Autocomplete;
