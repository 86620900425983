import { useMemo } from 'react';
import { useTranslations } from 'next-intl';

import { CourseType, lookupCourseType } from '@/constants';
import { MysoftCourse } from '@fu/studio';

export type CourseTypes = Record<
  number,
  {
    id: CourseType;
    name: string;
    label: string;
    subLabel: string;
    count: number;
  }
>;

export function useCourseTypes(
  courses: Pick<MysoftCourse, 'type'>[] | undefined | null
) {
  const t = useTranslations();

  const courseTypes = useMemo(
    () =>
      !courses
        ? ({} as CourseTypes)
        : courses.reduce((acc: CourseTypes, course) => {
            if (typeof course.type !== 'number') {
              return acc;
            }

            const type = lookupCourseType(course.type);

            if (typeof acc[course.type] === 'undefined') {
              acc[course.type] = {
                id: course.type,
                name: type,
                label: t(`course.type.${type}.title`),
                subLabel: t(`course.type.${type}.description`),
                count: 0,
              };
            }

            acc[course.type].count += 1;

            return acc;
          }, {}),
    [courses, t]
  );

  return courseTypes;
}
