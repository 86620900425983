import { useMemo } from 'react';
import capitalize from 'just-capitalize';

import { MysoftCourse } from '@fu/studio';

import { isPhysicalCourse } from '@/utils';

export type CourseLocations = Record<
  string,
  { id: string; name: string; label: string; count: number }
>;

export function useCourseLocations(
  courses?: (Pick<MysoftCourse, 'type'> & { location?: string })[]
) {
  const courseLocations = useMemo(
    () =>
      !courses
        ? ({} as CourseLocations)
        : courses
            .filter(isPhysicalCourse)
            .reduce((acc: CourseLocations, course) => {
              const location = course.location?.toLowerCase();

              if (typeof location !== 'string' || location === '') {
                return acc;
              }

              if (typeof acc[location] !== 'undefined') {
                acc[location].count += 1;
                return acc;
              }

              acc[location] = {
                id: location,
                name: location,
                label: capitalize(location),
                count: 1,
              };

              return acc;
            }, {}),
    [courses]
  );

  return courseLocations;
}

export function useCourseLocationsForType(
  type: MysoftCourse['type'],
  courses: (Pick<MysoftCourse, 'type'> & { location?: string })[] = []
) {
  const courseLocations = useMemo(
    () =>
      courses
        .filter((course) => course.type === type)
        .reduce((acc: CourseLocations, course) => {
          const location = course.location?.toLowerCase();

          if (typeof location !== 'string' || location === '') {
            return acc;
          }

          if (typeof acc[location] !== 'undefined') {
            acc[location].count += 1;
            return acc;
          }

          acc[location] = {
            id: location,
            name: location,
            label: capitalize(location),
            count: 1,
          };

          return acc;
        }, {}),
    [type, courses]
  );

  return courseLocations;
}
