import { useEffect } from 'react';
import type { BaseSyntheticEvent, MouseEvent, KeyboardEvent } from 'react';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { ResizeObserver } from '@juggle/resize-observer';
import useMeasure, { RectReadOnly } from 'react-use-measure';
import clsx from 'clsx';
import type {
  AutocompleteApi,
  AutocompleteState,
} from '@algolia/autocomplete-core';

import { Close, Search as SearchIcon } from '@fu/icons';
import { IconButton, Anchor } from '@fu/ui';

import { useMediaQuery } from '@/hooks';

import { Portal } from '@/components/portal';
import { AutocompleteItem } from '@/components/algolia';
import { CurriculumLinkItem } from '@/components/algolia/autocomplete/curriculum-link-item';
import { StudyProgramLinkItem } from '@/components/algolia/autocomplete/study-program-link-item';
import { ArticleLinkItem } from '@/components/algolia/autocomplete/article-link-item';

export type ResultsModalProps = {
  autocomplete: AutocompleteApi<
    AutocompleteItem,
    BaseSyntheticEvent,
    MouseEvent,
    KeyboardEvent
  >;
  autocompleteState: AutocompleteState<AutocompleteItem>;
  inputBounds: RectReadOnly;
  position?: 'center' | 'right';
};

export function ResultsModal({
  position,
  autocomplete,
  autocompleteState,
  inputBounds,
}: ResultsModalProps) {
  const t = useTranslations('Search');

  const [comboboxRef, comboboxBounds] = useMeasure({
    polyfill: ResizeObserver,
  });

  // const { getEnvironmentProps } = autocomplete;

  // useEffect(() => {
  //   if (!(formRef.current && panelRef.current && inputRef.current)) {
  //     return;
  //   }

  //   /* eslint-disable-next-line @typescript-eslint/unbound-method */
  //   const { onTouchStart, onTouchMove, onMouseDown } = getEnvironmentProps({
  //     formElement: formRef.current,
  //     panelElement: panelRef.current,
  //     inputElement: inputRef.current,
  //   });

  //   window.addEventListener('touchstart', onTouchStart);
  //   window.addEventListener('touchmove', onTouchMove);
  //   window.addEventListener('mousedown', onMouseDown);

  //   return () => {
  //     window.removeEventListener('touchstart', onTouchStart);
  //     window.removeEventListener('touchmove', onTouchMove);
  //     window.removeEventListener('mousedown', onMouseDown);
  //   };
  // }, [getEnvironmentProps, autocompleteState.isOpen]);

  // console.log('@@@@@ autocompleteState', autocompleteState);

  const curriculaHits = (autocompleteState.context?.curricula ?? 0) as number;
  const articlesHits = (autocompleteState.context?.articles ?? 0) as number;
  const studyProgramsHits = (autocompleteState.context?.studyPrograms ??
    0) as number;

  const totalHits = curriculaHits + studyProgramsHits + articlesHits;

  const resultCollections =
    autocompleteState.collections?.filter(
      (col) =>
        !['querySuggestions', 'recentSearches'].includes(col.source.sourceId)
    ) ?? [];

  const isDesktop = useMediaQuery('(min-width: 768px)');
  const multiRow =
    isDesktop &&
    resultCollections.length > 1 &&
    resultCollections.every((col) => col.items.length > 0);

  useEffect(() => {
    if (autocompleteState.isOpen && !isDesktop) {
      document.body.style.overflow = 'hidden';
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [autocompleteState.isOpen, isDesktop]);

  // Temporary
  const queryLink = autocompleteState?.query
    ? `/sok?q=${encodeURIComponent(autocompleteState.query)}`
    : '/sok';

  return (
    <Portal id="fu-autocomplete">
      {autocompleteState.isOpen && (
        <>
          <div
            className={clsx(
              'invisible fixed md:visible',
              'top-0 left-0',
              'z-20',
              'h-full w-full',
              'bg-neutral-70 opacity-60'
            )}
            tabIndex={-1}
            aria-hidden
            onClick={() => autocomplete.setIsOpen(false)}
          />
          <section
            className={clsx(
              'absolute',
              'z-20',
              'rounded-lg bg-neutral-0 md:elevation-neutral-2xl',
              'p-6',
              {
                'w-[60vw] max-w-3xl': !multiRow,
                'w-[80vw] max-w-6xl': multiRow,
              },
              {
                'w-[100vw]': !isDesktop,
                'max-h-screen min-h-screen overflow-scroll': !isDesktop,
              }
            )}
            {...(isDesktop
              ? {
                  style: {
                    top: inputBounds.bottom + 16,
                    left:
                      position === 'right'
                        ? inputBounds.left +
                          inputBounds.width -
                          comboboxBounds.width
                        : inputBounds.left,
                    right: position === 'center' ? 0 : undefined,
                  },
                }
              : {
                  style: {
                    top: inputBounds.bottom + window.scrollY,
                    left: 0,
                  },
                })}
            ref={comboboxRef}
            {...autocomplete.getPanelProps({})}
          >
            {autocompleteState.query && (
              <header>
                <h2 className="hd-xl">
                  {t.rich('autocompleteTitle', {
                    totalhits: totalHits,
                    query: autocompleteState.query.trim(),
                    highlight: (chunks) => (
                      <span className="text-primary-50">{chunks}</span>
                    ),
                  })}
                </h2>
              </header>
            )}

            {/* {autocompleteState.collections
              ?.filter((col) => col.source.sourceId === 'recentSearches')
              .map(({ source, items }) => {
                return (
                  <>
                    <ul className="my-5" key={source.sourceId}>
                      {items.map((item) => (
                        <li key={item.objectID} className="flex items-center">
                          <SearchIcon className="mr-2" /> {item.label}
                        </li>
                      ))}
                    </ul>
                    <hr className=" my-8 border-neutral-40" />
                  </>
                );
              })} */}

            {autocompleteState.collections
              ?.filter((col) => col.source.sourceId === 'querySuggestions')
              .map(({ source, items }) => (
                <>
                  <ul className="my-5 w-min" key={source.sourceId}>
                    {items.map(
                      (item) =>
                        item.query && (
                          <li key={item.objectID}>
                            <Link
                              href={`/sok?q=${item.query}`}
                              className="flex items-center"
                            >
                              <SearchIcon className="mr-2" />
                              <span
                                className={clsx('hover:text-primary-50', {
                                  'text-primary-50':
                                    autocompleteState.activeItemId ===
                                    item.__autocomplete_id,
                                })}
                              >
                                {item.query}
                              </span>
                            </Link>
                          </li>
                        )
                    )}
                  </ul>
                  <hr className=" my-8 border-neutral-40" />
                </>
              ))}

            <div className="flex flex-col gap-8 md:flex-row md:gap-16">
              {resultCollections?.map(({ source, items }, i) => {
                // From design it looks like we should not show articles if there are no results...?
                // If so, this should be kept and we clean up the code below
                if (source.sourceId === 'article' && items.length === 0) {
                  return null;
                }
                return (
                  <section
                    key={source.sourceId}
                    className={
                      !multiRow
                        ? 'w-full'
                        : i === 0
                        ? 'w-[calc(60%-32px)] flex-none'
                        : ''
                    }
                  >
                    <header>
                      <h3 className="hd-lg flex justify-between">
                        {t(
                          `${source.sourceId as 'curriculum' | 'article'}.title`
                        )}
                        {source.sourceId && (
                          <Link
                            href={queryLink}
                            passHref
                            onClick={() => autocomplete.setIsOpen(false)}
                          >
                            <Anchor
                              size="sm"
                              iconRight="arrow-right"
                              variant="current"
                            >
                              {t('seeMoreResults', {
                                hits:
                                  source.sourceId === 'curriculum'
                                    ? curriculaHits
                                    : articlesHits,
                              })}
                            </Anchor>
                          </Link>
                        )}
                      </h3>
                    </header>

                    {items.length > 0 && (
                      <ul {...autocomplete.getListProps()} className="md:mt-4">
                        {items.map((item) => {
                          return (
                            <li
                              key={item.objectID}
                              {...autocomplete.getItemProps({
                                item,
                                source,
                              })}
                            >
                              {item._type === 'mysoft_curriculum' && (
                                <CurriculumLinkItem
                                  active={
                                    autocompleteState.activeItemId ===
                                    item.__autocomplete_id
                                  }
                                  {...item}
                                />
                              )}

                              {item._type === 'studd_study_program' && (
                                <StudyProgramLinkItem
                                  active={
                                    autocompleteState.activeItemId ===
                                    item.__autocomplete_id
                                  }
                                  {...item}
                                />
                              )}

                              {item._type === 'article' && (
                                <ArticleLinkItem
                                  active={
                                    autocompleteState.activeItemId ===
                                    item.__autocomplete_id
                                  }
                                  {...item}
                                />
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}

                    {items.length === 0 && (
                      <p className="mt-4">
                        {t(
                          `${
                            source.sourceId as 'curriculum' | 'article'
                          }.noResult`,
                          { query: autocompleteState.query.trim() }
                        )}
                      </p>
                    )}
                  </section>
                );
              })}
            </div>
            <IconButton
              variant="muted"
              className="invisible absolute top-6 right-8 sm:visible lg:top-8 lg:right-12"
              onClick={() => autocomplete.setIsOpen(false)}
              size="md"
              rounded
            >
              <Close />
            </IconButton>
          </section>
        </>
      )}
    </Portal>
  );
}

export default ResultsModal;
