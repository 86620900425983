import { useIntl, useTranslations } from 'next-intl';

import { CreditCard, Level, Clock, Location } from '@fu/icons';

import type { CurriculumPageData } from '@/components/curriculum-page';
import type { CurriculumListItemData } from '@/components/curriculum-list-item';
import type { AlgoliaCurriculumListItemData } from '@/components/algolia';

import { isPhysicalCourse } from '@/utils';

import type { KeyInfoItem } from '@/hooks/types';

type CurriculumPageDataKeyInformation = Pick<
  CurriculumPageData,
  'mainSubject' | 'languageLevel' | 'level' | 'courses'
>;

type CurriculumKeyInformationOptions =
  | {
      curriculum: CurriculumListItemData | AlgoliaCurriculumListItemData;
      page: 'PLP';
    }
  | {
      curriculum: CurriculumPageDataKeyInformation;
      page: 'PDP';
    };

function getCurriculumPrice(
  curriculum:
    | CurriculumListItemData
    | AlgoliaCurriculumListItemData
    | CurriculumPageDataKeyInformation
) {
  // Only return prices if they're the same for all courses!
  const prices = [
    ...new Set(
      (curriculum.courses
        ?.map((c) => c.price)
        .filter((price) => typeof price === 'number') as number[]) ?? []
    ),
  ];

  return prices.length === 1 ? prices[0] : null;
}

function getCurriculumHours(
  curriculum:
    | CurriculumListItemData
    | AlgoliaCurriculumListItemData
    | CurriculumPageDataKeyInformation
) {
  // Only return hours if they're the same for all courses!
  const hours = [
    ...new Set(
      (curriculum.courses?.map((c) => c.hours).filter(Boolean) as number[]) ??
        []
    ),
  ];

  return hours.length === 1 ? hours[0] : null;
}

function getCurriculumLocations(
  curriculum:
    | CurriculumListItemData
    | AlgoliaCurriculumListItemData
    | CurriculumPageDataKeyInformation
) {
  const locations = [
    ...new Set(
      (curriculum.courses
        ?.filter(isPhysicalCourse)
        ?.map((c) => c.location?.toLowerCase())
        .filter(Boolean) as string[]) ?? []
    ),
  ];

  return locations;
}

export function useCurriculumKeyInformation({
  curriculum,
  page,
}: CurriculumKeyInformationOptions) {
  const t = useTranslations();
  const intl = useIntl();

  const output: KeyInfoItem[] = [];

  const hours = getCurriculumHours(curriculum);

  if (hours && hours > 0) {
    output.push({
      key: 'hours',
      title: t('course.teachingHours'),
      icon: <Clock className="h-full w-full" />,
      value: `${hours}${page === 'PDP' ? '' : ` ${t('hours')}`}`,
    });
  }

  const price = getCurriculumPrice(curriculum);

  if (typeof price === 'number') {
    output.push({
      key: 'price',
      title: t('course.priceFrom'),
      icon: <CreditCard className="h-full w-full" />,
      value: intl
        .formatNumber(price, {
          style: 'currency',
          currency: 'NOK',
        })
        .replace(',00', ',-'),
    });
  } else {
    // output.push({
    //   key: 'financing',
    //   title: t('course.financing'),
    //   icon: <CreditCard className="h-full w-full" />,
    //   value: t('course.publiclyFunded'),
    // });
  }

  if (page === 'PDP') {
    const { mainSubject, languageLevel, level } = curriculum;

    if (mainSubject === 'Språkfag' && languageLevel) {
      output.push({
        key: 'languageLevel',
        title: t('course.languageLevel'),
        icon: <Level className="h-full w-full" />,
        // Show Language level instead of Level if the subject is Språkfag..
        value: languageLevel,
      });
    }

    if (mainSubject !== 'Språkfag' && level) {
      output.push({
        key: 'level',
        title: t('course.level'),
        icon: <Level className="h-full w-full" />,
        // Show Level instead of Language level if the subject is not Språkfag..
        value: level,
      });
    }
  }

  if (page === 'PLP') {
    const locations = getCurriculumLocations(curriculum);
    const locationCount = locations.length;

    if (locationCount > 0) {
      output.push({
        key: 'locations',
        title: '',
        icon: <Location className="h-full w-full" />,
        value:
          locationCount === 1
            ? locations[0]
            : `${locationCount} ${t('locations')}`,
      });
    }
  }

  return output;
}
