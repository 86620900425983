export function safeJSON<T>(str?: string | null) {
  if (!str) {
    return {} as T;
  }

  try {
    return JSON.parse(str) as T;
  } catch (err) {
    console.log('err', err);
  }

  return {} as T;
}

export function safeJSONArr<T>(str?: string | null) {
  if (!str) {
    return [] as T;
  }

  try {
    return JSON.parse(str) as T;
  } catch (err) {
    console.log('err', err);
  }

  return [] as T;
}
