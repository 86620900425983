import { useState, useRef, useEffect } from 'react';

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    function onChange() {
      if (!isMounted.current) return;

      return setMatches(media.matches);
    }

    media.addEventListener('change', onChange);

    return () => {
      isMounted.current = false;
      media.removeEventListener('change', onChange);
    };
  }, [matches, query]);

  return matches;
}

export default useMediaQuery;
