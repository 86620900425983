import Link from 'next/link';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';

import { ArrowRight } from '@fu/icons';
import { Badge } from '@fu/ui';

import type { AlgoliaStudyProgramListItemData } from '@/components/algolia';

export type StudyProgramLinkItemProps = AlgoliaStudyProgramListItemData & {
  active: boolean;
};

export function StudyProgramLinkItem({
  active,
  pathname,
  title,
}: StudyProgramLinkItemProps) {
  const t = useTranslations('Search');

  if (!pathname) return null;

  return (
    <Link
      href={pathname}
      className={clsx(
        'hd-base flex items-center justify-between',
        'border-b-1 border-neutral-40 py-4',
        { 'bg-neutral-30 px-2': active }
      )}
    >
      <span className="truncate">{title}</span>

      <Badge
        type="information"
        variant="neutral-light"
        className="ml-2 mr-auto flex-none"
      >
        {t('studyProgramBadge')}
      </Badge>

      <ArrowRight className="ml-4 flex-none" />
    </Link>
  );
}

export default StudyProgramLinkItem;
