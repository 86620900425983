import algoliasearch from 'algoliasearch';

export const configuredAlgoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_PROJECT_ID ?? '',
  process.env.ALGOLIA_WRITE_API_KEY ?? ''
);

export const getAlgoliaClient = () => configuredAlgoliaClient;

export const configuredAlgoliaSearchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_PROJECT_ID ?? '',
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY ?? ''
);
