// export const CurriculumRegistrationType = {
//   220410000: 'registration',
//   220410001: 'application',
// };

// export const CourseType = {
//   220410000: 'classroom',
//   220410001: 'virtual',
//   220410002: 'combination',
//   220410003: 'selfstudies',
// };

// export const CourseRegistrationType = {
//   220410000: 'registration',
//   220410001: 'application',
// };

// export const CourseStatus = {
//   220410000: 'draft',
//   220410001: 'planned',
//   220410002: 'open',
//   220410003: 'closed',
//   220410004: 'delayed',
//   220410005: 'canceled',
// };

export enum CurriculumRegistrationType {
  'registration' = 220410000,
  'application' = 220410001,
}

export const indexOfCurriculumRegistrationType = (
  value: CurriculumRegistrationType
) => Object.values(CurriculumRegistrationType).indexOf(value);

export const lookupCurriculumRegistrationType = (
  v: CurriculumRegistrationType
) =>
  Object.keys(CurriculumRegistrationType)[
    indexOfCurriculumRegistrationType(v)
  ] as keyof typeof CurriculumRegistrationType;

export enum CourseType {
  'classroom' = 220410000,
  'virtual' = 220410001,
  'combination' = 220410002,
  'selfstudies' = 220410003,
}

export const indexOfCourseType = (value: CourseType) =>
  Object.values(CourseType).indexOf(value);

export const lookupCourseType = (v: CourseType) =>
  Object.keys(CourseType)[indexOfCourseType(v)] as keyof typeof CourseType;

export enum CourseRegistrationType {
  'registration' = 220410000,
  'application' = 220410001,
}

export const indexOfCourseRegistrationType = (value: CourseRegistrationType) =>
  Object.values(CourseRegistrationType).indexOf(value);

export const lookupCourseRegistrationType = (v: CourseRegistrationType) =>
  Object.keys(CourseRegistrationType)[
    indexOfCourseRegistrationType(v)
  ] as keyof typeof CourseRegistrationType;

export enum CourseStatus {
  'draft' = 220410000,
  'planned' = 220410001,
  'open' = 220410002,
  'closed' = 220410003,
  'delayed' = 220410004,
  'canceled' = 220410005,
}

export const indexOfCourseStatus = (value: CourseStatus) =>
  Object.values(CourseStatus).indexOf(value);

export const lookupCourseStatus = (v: CourseStatus) =>
  Object.keys(CourseStatus)[
    indexOfCourseStatus(v)
  ] as keyof typeof CourseStatus;
