export function mergeRefs<T>(
  ...refs:
    | Array<React.MutableRefObject<T> | React.LegacyRef<T>>
    | Array<Array<React.MutableRefObject<T> | React.LegacyRef<T>>>
): React.RefCallback<T> {
  return (value) => {
    refs.flat().forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
