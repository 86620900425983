import { useIntl, useTranslations } from 'next-intl';
import { isValid } from 'date-fns';

import { Level, Clock, Grid, Location } from '@fu/icons';

import type { StudyProgramListItemData } from '@/components/study-program-list-item';
import type { StudyProgramPageData } from '@/components/study-program-page';
import type { KeyInfoItem } from '@/hooks/types';
import type { AlgoliaStudyProgramListItemData } from '@/components/algolia';

type StudyProgramPageDataKeyInformation = Pick<
  StudyProgramPageData,
  'category' | 'credits' | 'admissions'
>;

type StudyProgramKeyInformationOptions =
  | {
      studyProgram: StudyProgramListItemData | AlgoliaStudyProgramListItemData;
      page: 'PLP';
    }
  | {
      studyProgram: StudyProgramPageDataKeyInformation;
      page: 'PDP';
    };

function getStudyProgramStartDate(
  studyProgram: StudyProgramPageDataKeyInformation
) {
  const startDates = [
    ...new Set(
      (studyProgram.admissions
        ?.map((a) => a && a.teachingStartDate)
        .filter(Boolean) as string[]) ?? []
    ),
  ];

  const startDate = startDates.length === 1 ? startDates[0] : null;

  return startDate && isValid(new Date(startDate)) ? startDate : null;
}

function getStudyProgramLocations(
  studyProgram: StudyProgramListItemData | AlgoliaStudyProgramListItemData
) {
  const locations = [
    ...new Set(
      (studyProgram.admissions
        ?.map((a) => a && a.teachingLocationNames?.map((n) => n?.toLowerCase()))
        .flat()
        .filter(Boolean) as string[]) ?? []
    ),
  ];

  return locations;
}

export function useStudyProgramKeyInformation({
  studyProgram,
  page,
}: StudyProgramKeyInformationOptions) {
  const t = useTranslations();
  const intl = useIntl();

  const output: KeyInfoItem[] = [];

  if (page === 'PDP') {
    const startDate = getStudyProgramStartDate(studyProgram);

    if (startDate) {
      output.push({
        key: 'start',
        title: t('course.startDate'),
        icon: <Clock className="h-full w-full" />,
        value: intl.formatDateTime(new Date(startDate), {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }),
      });
    }

    if (studyProgram.category) {
      output.push({
        key: 'subject',
        title: t('course.subjectArea'),
        icon: <Level className="h-full w-full" />,
        value: studyProgram.category,
      });
    }
  }

  // output.push({
  //   key: 'financing',
  //   title: t('course.financing'),
  //   icon: <CreditCard className="h-full w-full" />,
  //   value: t('course.subsidized'),
  // });

  if (studyProgram.credits) {
    output.push({
      key: 'credits',
      title: t('course.credits'),
      icon: <Grid className="h-full w-full" />,
      value: `${studyProgram.credits} ${t('course.creditsSuffix')}`,
    });
  }

  if (page === 'PLP') {
    const locations = getStudyProgramLocations(studyProgram);
    const locationCount = locations.length;

    if (locationCount > 0) {
      output.push({
        key: 'locations',
        title: '',
        icon: <Location className="h-full w-full" />,
        value:
          locationCount === 1
            ? locations[0]
            : `${locationCount} ${t('locations')}`,
      });
    }
  }

  return output;
}
