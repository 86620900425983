import Link from 'next/link';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';

import { ArrowRight } from '@fu/icons';
import { Badge } from '@fu/ui';

import type { AlgoliaCurriculumListItemData } from '@/components/algolia';

export type CurriculumLinkItemProps = AlgoliaCurriculumListItemData & {
  active: boolean;
};

export function CurriculumLinkItem({
  active,
  pathname,
  title,
  courses,
}: CurriculumLinkItemProps) {
  const t = useTranslations('Search');

  if (!pathname) return null;

  return (
    <Link
      href={pathname}
      className={clsx(
        'hd-base flex items-center justify-between',
        'border-b-1 border-neutral-40 py-4',
        { 'bg-neutral-30 px-2': active }
      )}
    >
      <span className="truncate">{title}</span>

      <Badge
        type="information"
        variant="neutral-light"
        className="ml-2 mr-auto flex-none"
      >
        {courses?.length
          ? t('courseBadgeWithCount', {
              count: courses.length,
            })
          : t('courseBadge')}
      </Badge>

      <ArrowRight className="ml-4 flex-none" />
    </Link>
  );
}

export default CurriculumLinkItem;
