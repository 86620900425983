import { useRef, useEffect, isValidElement } from 'react';
import { createPortal } from 'react-dom';

function isBrowser() {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
}

interface PortalProps {
  id?: string;
  className?: string;
  children?: React.ReactNode;
}

export function Portal({
  id,
  className,
  children,
}: PortalProps): React.ReactPortal | null {
  const portal = useRef<Element | null>(
    isBrowser() ? document.createElement('div') : null
  );

  useEffect(() => {
    if (isBrowser() && !portal.current) {
      portal.current = document.createElement('div');
    }

    if (id) {
      portal.current?.setAttribute('id', id);
    }

    if (className) {
      portal.current?.classList.add(...className.split(' '));
    }
  }, []);

  useEffect(() => {
    const target = document ? document.body : null;

    if (
      !isBrowser() ||
      !(target instanceof HTMLElement) ||
      !(portal.current instanceof HTMLElement)
    ) {
      return;
    }

    const node = portal.current;
    target.appendChild(node);

    return () => {
      target.removeChild(node);
    };
  }, [portal]);

  if (!portal.current || !isValidElement(children)) {
    return null;
  }

  return createPortal(children, portal.current);
}

export default Portal;
