import type { MysoftCourse } from '@fu/studio';

import { CourseType } from '@/constants';

export function isPhysical(type: MysoftCourse['type']) {
  return type === CourseType.classroom || type === CourseType.combination;
}

export function isPhysicalCourse(course: Pick<MysoftCourse, 'type'>) {
  return isPhysical(course.type);
}
