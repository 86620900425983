import { useIntl, useTranslations } from 'next-intl';

import { CreditCard, Clock } from '@fu/icons';

import type { CurriculumPageCourseData } from '@/components/curriculum-page';
import type { KeyInfoItem } from '@/hooks/types';

export function useCourseKeyInformation({
  hours,
  price,
}: CurriculumPageCourseData) {
  const t = useTranslations();
  const intl = useIntl();

  const output: KeyInfoItem[] = [];

  if (hours && hours > 0) {
    output.push({
      key: 'hours',
      title: t('course.teachingHours'),
      icon: <Clock className="h-full w-full" />,
      value: `${hours} ${t('hours')}`,
    });
  }

  if (price && price > 0) {
    output.push({
      key: 'price',
      title: t('course.priceFrom'),
      icon: <CreditCard className="h-full w-full" />,
      value: intl
        .formatNumber(price, {
          style: 'currency',
          currency: 'NOK',
        })
        .replace(',00', ',-'),
    });
  } else {
    // output.push({
    //   key: 'financing',
    //   title: t('course.financing'),
    //   icon: <CreditCard className="h-full w-full" />,
    //   value: t('course.publiclyFunded'),
    // });
  }

  return output;
}
