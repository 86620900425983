import Link from 'next/link';
import Image from 'next/image';
import { useIntl } from 'next-intl';
import clsx from 'clsx';

import type { AlgoliaArticleListItemData } from '@/components/algolia';

export type ArticleLinkItemProps = AlgoliaArticleListItemData & {
  active: boolean;
};

export function ArticleLinkItem({
  active,
  slug,
  // title,
  _createdAt,
  readingTime,
  heading,
  image,
}: ArticleLinkItemProps) {
  const intl = useIntl();

  return (
    <Link
      href={`/artikler/${slug}`}
      className={clsx('my-4 flex justify-between', {
        'bg-neutral-30': active,
      })}
    >
      <span className="flex">
        <span className="relative mr-4 block h-20 w-20 flex-none">
          {image?.src && (
            <Image
              className="absolute top-0 left-0 h-full w-full object-cover"
              loader={({ src }) => src}
              alt={image.alt ?? 'mangler bildebeskrivelse'}
              src={image.src}
              // sizes="(max-width: 640px) 120px, 120px"
              style={{ objectFit: 'cover' }}
              width="120"
              height="120"
              placeholder="empty"
              unoptimized
              // fill
            />
          )}
        </span>
        <span className="flex flex-col justify-center">
          <span className="meta-xs mb-2">
            {intl.formatDateTime(new Date(_createdAt), {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })}
            {readingTime ? ` - ${readingTime.toString()} min` : ''}
          </span>
          <span className="hd-base">{heading}</span>
        </span>
      </span>
    </Link>
  );
}

export default ArticleLinkItem;
